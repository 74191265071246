<template>
  <div id="main">
    <v-row>
      <v-col>
        <smid-searchbar :tweets="tweetsX" :keywords="queryX" updateTweetsType="updateRawTweets" updateKeywordsType="updateRawKeywords"></smid-searchbar>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <smid-raw :tweets="tweetsX"></smid-raw>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import SearchBar from '@/components/SearchBar'
import Raw from '@/components/Raw'

export default {
  name: 'Home',
  components:{
    "smid-searchbar": SearchBar,
    "smid-raw": Raw,
  },

  data: function() {
    return {
      url: window.location.hostname == 'localhost' ? 'http://localhost:8000/' : 'https://' + window.location.hostname + '/api/',
      tweetsX: this.$store.state.tweetsRaw,
      queryX: this.$store.state.keywordsRaw,
    }
  },
  methods: {
  },
  watch: {
  },
  computed: {
  }
}

</script>

<style>
</style>

