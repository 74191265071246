import Vue from 'vue'
import VueRouter from 'vue-router'
import TopicView from '../views/TopicView.vue'
import EmojisView from '../views/EmojisView.vue'
import MapView from '../views/MapView.vue'
import NetworkView from '../views/NetworkView.vue'
import Help from '../views/Help.vue'
import Evaluation from '../views/Evaluation.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'NetworkView',
    component: NetworkView
  },
  {
    path: '/emojis',
    name: 'EmojisView',
    component: EmojisView
  },
  {
    path: '/map',
    name: 'MapView',
    component: MapView
  },
  {
    path: '/network',
    name: 'NetworkView',
    component: NetworkView
  },
  {
    path: '/topics',
    name: 'TopicView',
    component: TopicView
  },
  {
    path: '/help',
    name: 'Help',
    component: Help
  },
  {
    path: '/tweets',
    name: 'Tweets',
    component: Evaluation
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

