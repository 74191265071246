<template>
  <div :style="{ height: dynamicHeight + 'px' }">
    <l-map style="height:100%;" ref="map" :center="center" :zoom="zoom" @update:center="centerUpdate" @update:zoom="zoomUpdate">
      <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" :attribution="attribution"/>
      <LeafletHeatmap ref="heatmap" :key="heatmapKey" :latLng="tweetLocationArray" :min-opacity="10" :blur="21" :radius="15"></LeafletHeatmap>
    </l-map>
  </div>
</template>


<script>
import {latLng } from 'leaflet';
import LeafletHeatmap from '@/assets/dependencies/Vue2LeafletHeatmap'

export default {
  namapHeightme: 'Map',

  components:{
    LeafletHeatmap
  },

  mounted() {
    this.reCalc(this.tweets);
  },

  watch: {
    tweets(newTweets) {
      if (this.$refs.heatmap) this.$refs.heatmap.clearMap();
      if (this.$refs.heatmap) this.$refs.heatmap.addLatLng(0, 0, 1);

        this.reCalc(newTweets);
    },
  },

  methods: {
    reCalc: function (tweetList) {
      if (this.$refs.heatmap) this.$refs.heatmap.clearMap();
      this.tweetLocationArray = [];
      for (let i = 0; i < tweetList.length; i++) {
        let tweet = tweetList[i];
        if (tweet.lat != null && tweet.lng != null) {
          this.tweetLocationArray.push(
            [tweet.lat, tweet.lng]
          );
          if (this.$refs.heatmap) this.$refs.heatmap.addLatLng(latLng(tweet.lat, tweet.lng, 1))
        }
      }
      console.log(this.tweetLocationArray);
    },

    centerUpdate(center) {
      this.currentCenter = center;
    },

    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
  },

  data: function() {
    return {
      selectedIncident: null,
      binSize: 5,
      center: latLng(52.140231, 4.87726),
      currentCenter: latLng(52.140231, 4.87726),
      zoom: 7,
      currentZoom: 7,
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      heatmapKey: 0,
      windowHeight: window.innerHeight,
      latLngField: "latLng", // Field containing latLng information in the data array
      valueField: "value",   // Field containing values for heatmap intensity
      tweetLocationArray: [
      ],       // Array containing heatmap data
    }
  },

  computed: {
    dynamicHeight() {
      const otherElement = this.$refs.appBar;
      const seachBar = this.$refs.searchBar;
      const otherElementHeight = otherElement ? otherElement.clientHeight : 64;
      const seachBarHeight = seachBar ? seachBar.clientHeight : 76;
      const margin = 60;
      return window.innerHeight - otherElementHeight - seachBarHeight - margin;
    },
    tweets() {
      return this.$store.state.tweetsMap;
    },
  },
}


</script>

<style scoped>
  .vue2leaflet-map {
    z-index: 0;
  }
</style>

