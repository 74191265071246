<template>
  <div id="searchBar" :style="{ width: '100%' }">
          <v-combobox
            clearable
            chips
            multiple
            v-model="keyword"
            :items="keywordsDisp"
            label="Keywords"
            placeholder="Type or select keyword"
            close-on-content-click="true"
          >
          </v-combobox>
  </div>
</template>


<script>

export default {
  components: {
  },

  data: function() {
    return {
      selectedIncident: null,
      container: null,
      errorMessages: '',
      keyword: null,
      formHasErrors: false,
      keywordsDisp: [],
      tweetsOrg: this.$store.state.tweetsOrg,
    }
  },

  props: {
    tweets: {
      type: Array,
      default: () => [],
    },
    updateTweetsType: String,
    updateKeywordsType: String,
    keywords: {
      type: Array,
      default: () => [],
    },
  },

  mounted() {
    for (let i = 0; i < this.tweets.length; i++) {
      let keyWords = Object.keys(this.tweets[i].keyword_clusters);
      for (let j = 0; j < keyWords.length; j++) {
        let keyWord = keyWords[j];
        this.keywordsDisp.push(keyWord);
      }
    }
    console.log(this.keywordsDisp);
    this.keyword = this.keywords;
  },

  computed: {
    form () {
      return {
        keyword: this.keyword,
      }
    },
  },

  watch: {
    name () {
      this.errorMessages = ''
    },

    keyword (selectedKeywords, previousKeyword) {
      console.log(previousKeyword);
      console.log("sel keywords");
      console.log(selectedKeywords);
      console.log(this.tweetsOrg);
      let newTweets = [];
      // TODO include OR functionality (keep AND)
      this.keywordsDisp = [];
      for (let i = 0; i < this.tweetsOrg.length; i++) {
        let keyWords = Object.keys(this.tweetsOrg[i].keyword_clusters);
        let addTweet = true;
        for (let j = 0; j < selectedKeywords.length; j++) {
          if (!keyWords.includes(selectedKeywords[j])) {
            addTweet = false;
            break;
          }
        }
        if (addTweet) {
          newTweets.push(this.tweetsOrg[i]);
          let keyWords = Object.keys(this.tweetsOrg[i].keyword_clusters);
          for (let j = 0; j < keyWords.length; j++) {
            let keyWord = keyWords[j];
            this.keywordsDisp.push(keyWord);
          }
        }
      }
      this.updateTweets(newTweets);
      this.updateKeywords(selectedKeywords);
    },
  },

  methods: {
    updateTweets(newTweetList) {
      this.$store.commit(this.updateTweetsType, newTweetList);
    },
    updateKeywords(newKeywordList) {
      this.$store.commit(this.updateKeywordsType, newKeywordList);
    },
  },
}
</script>

<style scoped>
.custom-item-class {
  background-color: yellow; /* Set the desired background color here */
}
</style>

