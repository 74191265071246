<template>
  <v-navigation-drawer
    app
    color="secondary"
    :mini-variant="drawer"
    permanent
    clipped
    width="200"
  >
    <v-list
      nav
      dense
    >
      <v-list-item v-for="item in items" v-bind:key="item.name" link :to="item.to">
        <v-list-item-icon>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
              >
                {{ item.icon }}
              </v-icon>
            </template>
            <span>{{ item.name }}</span>
          </v-tooltip>
        </v-list-item-icon>
        <v-list-item-title>{{ item.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: [
    'drawer'
  ],
  data: function() {
    return {
      items: [
        //{ name: "Dashboard", to: "/", icon: "mdi-view-dashboard" },
        { name: "Network", to: "/network", icon: "mdi-blur" },
        { name: "Topics", to: "/topics", icon: "mdi-view-headline" },
        { name: "Emojis", to: "/emojis", icon: "mdi-emoticon" },
        { name: "Map", to: "/map", icon: "mdi-map" },
        { name: "Tweets", to: "/tweets", icon: "mdi-format-list-text"},
        { name: "Help", to: "/help", icon: "mdi-help-circle" }
      ]
    }
  }
}
</script>

