<template>
  <div id="main">
    <v-row>
      <v-col>
        <smid-searchbar :tweets="tweetsX" :keywords="queryX" updateTweetsType="updateEmojisTweets" updateKeywordsType="updateEmojisKeywords"></smid-searchbar>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <smid-emoji-bar :tweets="tweetsX"></smid-emoji-bar>
      </v-col>
    </v-row>
    <smid-confirm-dialog ref="confirm" />
  </div>
</template>

<script>
// @ is an alias to /src
import SearchBar from '@/components/SearchBar'
import EmojiBar from '@/components/EmojiBar'
import ConfirmDlg from '@/components/ConfirmDlg'

export default {
  name: 'Home',
  components:{
    "smid-searchbar": SearchBar,
    "smid-emoji-bar": EmojiBar,
    "smid-confirm-dialog": ConfirmDlg,
  },
  data: function() {
    return {
      url: window.location.hostname == 'localhost' ? 'http://localhost:8000/' : 'https://' + window.location.hostname + '/api/',
      tweetsX: this.$store.state.tweetsEmojis,
      queryX: this.$store.state.keywordsEmojis,
    }
  },
  methods: {
  },
  watch: {
  },
  computed: {
  }
}

</script>

<style>
  .timeline-bar {
    display: inline-block;
    min-height: 1px;
    width: 100%;
    background-color:#004682;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #000;
  }
  .timeline-container {
    display: flex; 
    flex-direction: column;
    line-height: 1em;
  }
  .timeline-spacer {
    flex-grow: 1;
  }
  .timeline-labels {
    font-size: 0.75em;
  }
  .timeline-x-labels {
    position:absolute;
    bottom:-2.75em;
    border-left: 1px dashed grey;
  }
  .timeline-y-col1 {
    min-width: 25px !important;
    max-width: 25px !important;
    margin-top: 50px;
  }
  .timeline-y-col2 {
    min-width: 35px !important;
    max-width: 35px !important;
    border-right: 1px solid #000;
    text-align: right;
  }
  .timeline-y-label {
    position: absolute;
    left: 25px;
    width: calc(100% - 27px);
    border-top: 1px dashed #004682;
    padding-right: 2px;
  }
  .timeline-y-zero {
    bottom: -1.3em;
  }
  .timeline-y-label > span {
    display: block;
    margin-top: 0px;
  }
  .timeline-row {
    position:relative; 
    height:200px; 
    margin: 0 0 calc(1.6em + 15px) 0 !important;
  }
  .incident-new {
    background: #EF9A9A;
  }
</style>

